import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'state';
import { Source } from 'types/Source';
import { filterBySearchTerm } from 'utils/filterBySearchTerm';

export const filterInstalledIntegrations = createSelector(
  [
    (state: RootState) => state.integrations.searchTerm,
    (state: RootState) => state.integrations.isSortAscending,
    (state: RootState, installedIntegrations: Source[]) => installedIntegrations,
  ],
  (searchTerm, isSortAscending, installedIntegrations) => {
    const filteredIntegrations = filterBySearchTerm(installedIntegrations, searchTerm);

    return filteredIntegrations.sort((a, b) => {
      const comparison = a.name.localeCompare(b.name);
      return isSortAscending ? comparison : -comparison;
    });
  }
);
