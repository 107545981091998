import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useStyles2, LinkButton, Tooltip } from '@grafana/ui';

import { useSelectedAgentOrSaasIntegration } from 'api/integrations/hooks';
import { Pages } from 'e2eSelectors/pages';
import { useDispatch } from 'hooks/useDispatch';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { RootState } from 'state';
import { Status } from 'utils/enums';
import { constructAlertUrl, constructDashboardUrl } from 'utils/misc';

import { clickAlertsAndRecordingButtons, clickDashboardsButton } from '../state/slice';

import { getStyles } from './ViewYourDashboardsSection.styles';

import { isAssertsAppEnabled } from 'utils/featureToggles';
import useRudderStack from 'hooks/useRudderstack';
import { RudderStackEvents } from 'utils/enums';

export const ViewYourDashboardsSection = () => {
  const dispatch = useDispatch();

  const styles = useStyles2(getStyles);
  const source = useSelectedAgentOrSaasIntegration();
  const sourceDetails = useSelector((state: RootState) => state.source.sourceDetails);
  const integrationId = useSelectedIntegrationId();
  const { trackRudderStackEvent } = useRudderStack();

  const { alerts, rules, dashboard_folder } = useSelector((state: RootState) => state.source.sourceDetails);
  const shouldShowAsserts = isAssertsAppEnabled() && sourceDetails.asserts?.status === Status.Available;

  const integrationDashboardsUrl = useMemo(
    () => (source.installation && dashboard_folder ? constructDashboardUrl(dashboard_folder) : '/dashboards'),
    [source.installation, dashboard_folder]
  );

  const {
    configuredParameters: { isAlertsEnabled },
  } = useSelector((state: RootState) => state.agent);

  const showAlertsText = isAlertsEnabled === undefined ? true : isAlertsEnabled;
  const areAlertsAvailable = alerts?.status === Status.Available;
  const displayAlertsInTheText = areAlertsAvailable && showAlertsText;

  const areRulesAvailable = rules?.status === Status.Available;

  const additionalText = (() => {
    if (areAlertsAvailable && showAlertsText && areRulesAvailable) {
      return ', alerts and recording rules';
    }
    if (areAlertsAvailable && showAlertsText) {
      return ', alerts';
    }
    if (areRulesAvailable) {
      return ', and recording rules';
    }
    return '';
  })();

  const isButtonDisabled = !source?.installation;

  const getButtonText = () => {
    if (areAlertsAvailable && showAlertsText && areRulesAvailable) {
      return 'View alerts and recording rules';
    }
    if (areAlertsAvailable && showAlertsText) {
      return 'View alerts';
    }
    if (areRulesAvailable) {
      return 'View recording rules';
    }
    return '';
  };

  const alertsAndRulesButtonText = getButtonText();
  const dashboardsAndAlertsButtons = (
    <span>
      <LinkButton
        variant="primary"
        fill="outline"
        icon="apps"
        role="link"
        href={integrationDashboardsUrl}
        className={styles.button}
        onClick={() => dispatch(clickDashboardsButton(integrationId))}
        disabled={isButtonDisabled}
        data-testid={Pages.Source.Config.viewDashboardsBottomButton}
      >
        View Dashboards
      </LinkButton>
      {alertsAndRulesButtonText && (
        <LinkButton
          variant="primary"
          fill="outline"
          icon="bell"
          role="link"
          href={constructAlertUrl({ alerts, rules })}
          className={styles.button}
          onClick={() => dispatch(clickAlertsAndRecordingButtons(integrationId))}
          disabled={isButtonDisabled}
        >
          {alertsAndRulesButtonText}
        </LinkButton>
      )}
      { shouldShowAsserts && (
        <LinkButton
          variant="primary"
          fill="outline"
          icon="asserts"
          role="link"
          href="/a/grafana-asserts-app/entities"
          className={styles.button}
          onClick={() =>
            trackRudderStackEvent(RudderStackEvents.ViewInAsserts, {
              integration_slug: integrationId,
            })
          }
          disabled={isButtonDisabled}
        >
          View in Asserts
        </LinkButton>
      )}

    </span>
  );

  return (
    <li>
      <h2>View your dashboards {displayAlertsInTheText && 'and alerts'}</h2>
      <p className={styles.p}>
        This integration comes with pre-built dashboards
        {additionalText} to get you up and running quickly.
      </p>
      {source.installation ? (
        dashboardsAndAlertsButtons
      ) : (
        <Tooltip content="Dashboards are not currently installed. Install them in the previous step.">
          {dashboardsAndAlertsButtons}
        </Tooltip>
      )}
    </li>
  );
};
