import { cx } from '@emotion/css';
import React from 'react';

import { useStyles2, Icon } from '@grafana/ui';

import { getStyles } from 'components/StatusMessage/StatusMessage.styles';
import { Components } from 'e2eSelectors/components';

type Props = {
  status: 'error' | 'warning' | 'success';
  children: React.ReactNode;
  className?: string;
  size?: 'base' | 'sm';
  ariaLabel?: string;
  role?: string;
  dataTestId?: string;
};

export function StatusMessage(props: Props) {
  const styles = useStyles2(getStyles);
  const isSizeBase = props.size === 'base';

  const icon =
    props.status === 'error' || props.status === 'warning' ? (
      <Icon size={isSizeBase ? 'md' : 'sm'} name="exclamation-triangle" className={styles.icon} />
    ) : (
      <Icon size={isSizeBase ? 'md' : 'sm'} name="check" className={styles.icon} />
    );

  return (
    <div
      data-testid={props.dataTestId ?? Components.Alert.alert}
      className={cx(
        styles.alert,
        props.className,
        props.status === 'error' ? styles.error : props.status === 'warning' ? styles.warning : styles.success,
        isSizeBase && styles.base
      )}
    >
      {icon}
      <p
        role={props.role ?? 'alert'}
        aria-label={props.ariaLabel || `${props.status} message`}
        className={styles.alertContent}
      >
        {props.children}
      </p>
    </div>
  );
}
