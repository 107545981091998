import React from 'react';

import { Button } from '@grafana/ui';

import { useQueryParams } from 'hooks';
import useRudderStack from "hooks/useRudderstack";
import {RudderStackEvents} from "utils/enums";

export const ResetFilters = () => {
  const [_, setQueryParams] = useQueryParams();
  const { trackRudderStackEvent } = useRudderStack();

  const handleReset = () => {

    trackRudderStackEvent(RudderStackEvents.FilterReset, {});
    setQueryParams({
      cat: undefined,
      type: undefined,
      search: undefined,
      searchCat: undefined,
    });
  };

  return (
    <>
      <p>Not what you were looking for?</p>
      <Button fill="solid" variant="secondary" onClick={handleReset}>
        Clear all filters to see more search results
      </Button>
    </>
  );
};
