import React, { FC, useEffect } from 'react';

import { useStyles2 } from '@grafana/ui';

import { StatusMessage } from 'components/StatusMessage';
import { Pages } from 'e2eSelectors/pages';
import useRudderStack from 'hooks/useRudderstack';
import {
  HostedExportersApiErrorResult,
  isCheckConnectionError,
  isErrorResponse,
  isHostedExportersApiErrorResult,
} from 'models/api-models';
import { RudderStackEvents } from 'utils/enums';

import { getJobStatusWidgetStyles } from './JobStatusWidget.styles';

export const JobFailedWidget: FC<{ sourceId: string | null; error: any }> = ({ sourceId, error }) => {
  const styles = useStyles2(getJobStatusWidgetStyles);
  const { trackRudderStackEvent } = useRudderStack();

  let message = '';
  let action: React.JSX.Element | undefined;
  if (isCheckConnectionError(error)) {
    message = error.data.error.message;
    action = undefined;
  } else if (isErrorResponse<HostedExportersApiErrorResult>(error) && isHostedExportersApiErrorResult(error?.data)) {
    message = error.data.error.message;
    action = undefined;
  }

  useEffect(() => {
    trackRudderStackEvent(RudderStackEvents.CreateOrUpdateScrapeJobFailed, {
      integration_slug: sourceId,
      message,
    });
  }, [sourceId, message, trackRudderStackEvent]);

  return (
    <StatusMessage
      className={styles.createScrapeJobStatus}
      status="error"
      ariaLabel="Connection error"
      dataTestId={Pages.SaaSIntegration.alertParagraph('creation-failed')}
    >
      {message}
      {action}
    </StatusMessage>
  );
};
