import { css } from '@emotion/css';
import _, { debounce } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { PluginPage } from '@grafana/runtime';
import { Icon, Input, LinkButton, useStyles2 } from '@grafana/ui';

import { useInstalledIntegrations } from 'api/integrations/hooks';
import { AlertFromRtqError } from 'components/AlertFromRtqError';
import { NoResults } from 'components/NoResults';
import { useDispatch } from 'hooks/useDispatch';
import useRudderStack from 'hooks/useRudderstack';
import { RootState } from 'state';
import { RudderStackEvents } from 'utils/enums';
import { CONNECT_DATA_URL } from 'utils/misc';

import { IntegrationItem } from './IntegrationItem';
import { ASCENDING_SORT_VALUE, SortPicker } from './SortPicker';
import { filterInstalledIntegrations } from './state/selectors';
import { clickAddNewInfraIntegration, initIntegrations, selectSort, typeIntegrationSearch } from './state/slice';

const getStyles = (theme: GrafanaTheme2) => ({
  actionBar: css`
    display: flex;
    flex-direction: row;
    margin-bottom: ${theme.spacing(5)};
    margin-top: ${theme.spacing(3)};
  `,
  sortPicker: css`
    margin-left: ${theme.spacing(3)};
  `,
});

export const Integrations = () => {
  const styles = useStyles2(getStyles);
  const isSortAscending = useSelector((state: RootState) => state.integrations.isSortAscending);

  const { installedIntegrations, erroneousQuery } = useInstalledIntegrations();

  const integrations = useSelector((state: RootState) => filterInstalledIntegrations(state, installedIntegrations));

  const dispatch = useDispatch();
  const { trackRudderStackEvent } = useRudderStack();

  const debouncedTrackEvent = debounce((searchTerm: string) => {
    trackRudderStackEvent(RudderStackEvents.InfraSearchInput, { searchTerm });
  }, 1500);

  const AddNewInfraIntegrationButton = (
    <LinkButton
      href={`${CONNECT_DATA_URL}?cat=integration`}
      icon="plus"
      onClick={() => {
        dispatch(clickAddNewInfraIntegration());
        trackRudderStackEvent(RudderStackEvents.AddNewInfraIntegrationClick, {});
      }}
    >
      Add new integration
    </LinkButton>
  );

  useEffect(() => {
    dispatch(initIntegrations());
    trackRudderStackEvent(RudderStackEvents.InfraPageView, {});
  }, [dispatch, trackRudderStackEvent]);

  return (
    <PluginPage actions={AddNewInfraIntegrationButton}>
      {erroneousQuery !== undefined ? (
        <AlertFromRtqError {...erroneousQuery} />
      ) : (
        <>
          <div className={styles.actionBar}>
            <Input
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                dispatch(typeIntegrationSearch(e.currentTarget.value.toLowerCase()));
                debouncedTrackEvent(e.currentTarget.value.toLowerCase());
              }}
              prefix={<Icon name="search" />}
              placeholder="Search all"
              aria-label="Search all by name"
            />
            <SortPicker
              onChange={(sort: SelectableValue) => {
                dispatch(selectSort(sort.value === ASCENDING_SORT_VALUE));
                trackRudderStackEvent(RudderStackEvents.InfraSortSelected, {
                  ascending: sort.value === ASCENDING_SORT_VALUE,
                });
              }}
              isAscending={isSortAscending}
              className={styles.sortPicker}
            />
          </div>

          {integrations.map((integration) => (
            <IntegrationItem integration={integration} key={integration.id} />
          ))}

          {integrations.length === 0 && <NoResults />}
        </>
      )}
    </PluginPage>
  );
};
