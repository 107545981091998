import { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

import { grafanaComApi } from 'api/grafanaCom/grafanaComApi';
import {
  AgentStatus,
  copyToClipboard,
  setAgentStatus,
  IntegrationConnectionStatus,
  setIntegrationConnectionStatus,
  clickOpenAgentConfigModal,
  clickAlertsAndRecordingButtons,
  clickDashboardsButton,
} from 'features/agent-integrations/state/slice';
import { apiTokenGenerateSuccess } from 'features/app/state/slice';
import { RootState } from 'state';
import { HOSTED_DATA_DETAILS_FALLBACK } from 'utils/consts';
import { RudderStackEvents } from 'utils/enums';

import {
  InstallationStatus,
  changeTab,
  clickOpenUninstallModal,
  clickViewDashboards,
  openSourcePage,
  setIntegrationInstallationStatus,
  setIntegrationUpdateStatus,
} from './source/slice';

const genericTrackRudderStackEvent = (event: string, params: object, orgId: number, slug: string) => {
  (window as any).rudderanalytics?.track(event, {
    ...params,
    org_id: orgId,
    instance_slug: slug,
  });
};

export const userTracking: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  const state: RootState = api.getState();
  const hostedDataDetails = grafanaComApi.endpoints.getHostedDataDetails.select()(state).data ?? HOSTED_DATA_DETAILS_FALLBACK;
  const { slug, orgId } = hostedDataDetails;

const trackRudderStackEvent = (event: string, params: object) =>
    genericTrackRudderStackEvent(event, params, orgId, slug);

  switch (action.type) {
    case setAgentStatus.type:
      if (action.payload.agentStatus === AgentStatus.Pending) {
        trackRudderStackEvent(RudderStackEvents.TestConnectionClick, {
          integration_slug: action.payload.integrationId,
        });
      } else if (action.payload.agentStatus === AgentStatus.Success) {
        trackRudderStackEvent(RudderStackEvents.TestConnectionSucceeded, {
          integration_slug: action.payload.integrationId,
        });
      } else if (action.payload.agentStatus === AgentStatus.Error) {
        trackRudderStackEvent(RudderStackEvents.TestConnectionFailed, {
          integration_slug: action.payload.integrationId,
        });
      }
      break;
    case clickOpenUninstallModal.type:
      trackRudderStackEvent(RudderStackEvents.UninstallButton, {
        integration_slug: action.payload,
      });
      break;
    case clickViewDashboards.type:
      trackRudderStackEvent(RudderStackEvents.ViewDashboards, {
        integration_slug: action.payload,
      });
      break;
    case clickDashboardsButton.type:
      trackRudderStackEvent(RudderStackEvents.DashboardsButtonClick, { integration_slug: action.payload });
      break;
    case openSourcePage.type:
      trackRudderStackEvent(RudderStackEvents.ViewSource, { integration_slug: action.payload });
      break;
    case clickAlertsAndRecordingButtons.type:
      trackRudderStackEvent(RudderStackEvents.AlertsAndRecordingButtonClick, { integration_slug: action.payload });
      break;
    case clickOpenAgentConfigModal.type:
      trackRudderStackEvent(RudderStackEvents.OpenAgentConfigModalButton, { integration_slug: action.payload });
      break;
    case apiTokenGenerateSuccess.type:
      trackRudderStackEvent(RudderStackEvents.CreateApiKeySucceeded, { integration_slug: action.payload });
      break;
    case changeTab.type:
      trackRudderStackEvent(RudderStackEvents.TabChanged, {
        integration_slug: action.payload.integrationId,
        tab: action.payload.currentTab,
      });
      break;
    case setIntegrationUpdateStatus.type:
      if (action.payload.status === InstallationStatus.Error) {
        trackRudderStackEvent(RudderStackEvents.UpdateFailed, {
          integration_slug: action.payload.integrationId,
        });
      } else if (action.payload.status === InstallationStatus.Success) {
        trackRudderStackEvent(RudderStackEvents.UpdateSucceeded, {
          integration_slug: action.payload.integrationId,
        });
      }
      break;
    case setIntegrationInstallationStatus.type:
      if (action.payload.status === InstallationStatus.Error) {
        trackRudderStackEvent(RudderStackEvents.InstallFailed, {
          integration_slug: action.payload.integrationId,
        });
      } else if (action.payload.status === InstallationStatus.Success) {
        trackRudderStackEvent(RudderStackEvents.InstallSuccess, {
          integration_slug: action.payload.integrationId,
        });
      }
      break;
    case setIntegrationConnectionStatus.type:
      if (action.payload.status === IntegrationConnectionStatus.Error) {
        trackRudderStackEvent(RudderStackEvents.TestIntegrationConnectionFailed, {
          integration_slug: action.payload.integrationId,
        });
      } else if (action.payload.status === IntegrationConnectionStatus.Success) {
        trackRudderStackEvent(RudderStackEvents.TestIntegrationConnectionSucceeded, {
          integration_slug: action.payload.integrationId,
        });
      } else if (action.payload.status === IntegrationConnectionStatus.Pending) {
        trackRudderStackEvent(RudderStackEvents.TestIntegrationConnectionClick, {
          integration_slug: action.payload.integrationId,
        });
      }
      break;
    case copyToClipboard.type:
      trackRudderStackEvent(RudderStackEvents.CopiedSnippetToClipboard, {
        integration_slug: action.payload,
        full_metrics_selected: state.agent.configuredParameters.isFullMetricsSelected,
      });
      break;

  }

  return next(action);
};

if (process.env.NODE_ENV === 'development') {
  (window as any).rudderanalytics = { track: console.log };
}
