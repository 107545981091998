import React, { FC, useEffect } from 'react';

import { useStyles2 } from '@grafana/ui';

import { StatusMessage } from 'components/StatusMessage';
import { Pages } from 'e2eSelectors/pages';
import useRudderStack from 'hooks/useRudderstack';
import { RudderStackEvents } from 'utils/enums';

import { getJobStatusWidgetStyles } from './JobStatusWidget.styles';

export const JobSuccessWidget: FC<{ sourceId: string | null; data: any }> = ({ sourceId, data }) => {
  const styles = useStyles2(getJobStatusWidgetStyles);
  const { trackRudderStackEvent } = useRudderStack();

  const message = 'All good! Scrape job created.';
  const services = !!data?.data
    ? Object.keys(data.data)
        .map((jobName) => data.data[jobName].services)
        .flat()
        .join(', ')
    : '';

  useEffect(() => {
    trackRudderStackEvent(RudderStackEvents.CreateOrUpdateScrapeJobSuccessfully, {
      integration_slug: sourceId,
      message,
    });
  }, [sourceId, message, services, trackRudderStackEvent]);

  return (
    <StatusMessage
      className={styles.createScrapeJobStatus}
      status="success"
      ariaLabel="Connection success"
      dataTestId={Pages.SaaSIntegration.alertParagraph('creation-successful')}
    >
      {message}
    </StatusMessage>
  );
};
