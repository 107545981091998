import { ApiIntegrationResult, SuccessfulApiResult } from 'models/api-models';
import { Source } from 'types/Source';
import { Datasource, GrafanaDatasource } from 'types/sources';

import { SourceType } from './enums';
import { getDatasourceUrl } from './misc';

export const DATASOURCE_PREFIX = 'datasource-';

export const formatGrafanaDatasourceList = (datasourceList: GrafanaDatasource[]): Source[] => {
  return datasourceList.map((datasource: GrafanaDatasource) => {
    const externalUrl = getDatasourceUrl(datasource.downloadSlug);
    return {
      id: DATASOURCE_PREFIX + datasource.downloadSlug,
      name: datasource.name,
      description: datasource.description,
      logo_url: `https://grafana.com/api/plugins/${datasource.downloadSlug}/logos/small`,
      type: SourceType.DataSource,
      plan: datasource.status,
      search_keywords: datasource.keywords,
      externalUrl,
      orgName: datasource.orgName,
    };
  });
};

export const formatDatasourceList = (datasourceList: Datasource[]): Source[] => {
  return datasourceList.map((datasource: Datasource) => {
    let datasourceName = datasource.name;
    if (['prometheus', 'loki', 'tempo', 'graphite'].includes(datasource.id)) {
      datasourceName = `${datasource.name} data source`;
    }

    const externalUrl = getDatasourceUrl(datasource.id);

    return {
      id: DATASOURCE_PREFIX + datasource.id,
      name: datasourceName,
      description: datasource.info.description,
      logo_url: datasource.info.logos.small,
      type: SourceType.DataSource,
      externalUrl,
      search_keywords: datasource.info.keywords,
      installation: {
        slug: '',
        stack_id: '',
        version: datasource.latestVersion,
        installed_on: '',
        dashboard_uids: null,
      },
      has_update: datasource.hasUpdate,
      orgName: datasource.info.author.name,
    };
  });
};

export function mapIntegrationsToSources(response: SuccessfulApiResult<ApiIntegrationResult>): Source[] {
  const integrations = Object.entries(response.data);
  return integrations.map(([id, integration]) => ({
    id,
    slug: integration.slug,
    name: integration.name,
    installation: integration.installation,
    logo_url: integration.logo.dark_theme_url,
    light_logo_url: integration.logo.light_theme_url,
    overview: integration.overview,
    type: integration.type,
    version: integration.version,
    dashboard_folder: integration.dashboard_folder,
    search_keywords: integration.search_keywords,
    categories: integration.categories,
    has_update: integration.has_update,
    rule_namespace: integration.rule_namespace,
    flavor: integration.flavor ?? undefined,
  }));
}
