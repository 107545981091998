import { css } from '@emotion/css';
import React, { FC, useState, useEffect } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, Alert, Card, Button } from '@grafana/ui';

import useRudderStack from 'hooks/useRudderstack';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { RudderStackEvents } from 'utils/enums';

import { GPUInstructions } from './GPUInstructions';
import { LLMInstructions } from './LLMInstructions';

const getStyles = (theme: GrafanaTheme2) => ({
  container: css({
    'ol li': {
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(3),
    },
    pre: {
      width: '60%',
    },
  }),
  cardContainer: css({
    display: 'flex',
    flexDirection: 'row',
  }),
  card: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '20px',
    marginRight: '20px',
    minHeight: '180px',
    maxWidth: '250px',
    '& h2': {
      fontSize: '18px',
    },
    ':hover': {
      cursor: 'pointer',
    }
  }),
  instructionSubheader: css({
    fontSize: '14px',
    fontStyle: 'italic',
    marginBottom: '10px',
  }),
  instructions: css({
    listStyleType: 'none',
  }),
  instructionsList: css({
    marginLeft: '30px',
    '& li': {
      marginBottom: '5px',
    },
  }),
  instructionNote: css({
    color: theme.colors.text.secondary,
    fontSize: '14px',
    fontStyle: 'italic',
    marginTop: '10px',
  }),
  h3: css({
    marginTop: '40px',
  }),
  apiGroup: css({
    width: '700px',
  }),
  link: css({
    cursor: 'pointer',
    textDecoration: 'underline',
  }),
  infoText: css({
    fontSize: '14px',
    marginBottom: '20px',
  }),
  promptText: css({
    fontSize: '18px',
    marginBottom: '20px',
  }),
});

export const AIObservabilityInstructions: FC = () => {
  const [showAlert, setShowAlert] = React.useState(true);
  const styles = useStyles2(getStyles);
  const { trackRudderStackEvent } = useRudderStack();
  const integrationId = useSelectedIntegrationId();
  const [display, setDisplay] = useState<string>('default');

  useEffect(() => {
    trackRudderStackEvent(RudderStackEvents.PageView, {
      integration_slug: integrationId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className={styles.container}>
        {showAlert ? (
          <Alert
            title="Public Preview: AI Observability Integrations"
            severity="info"
            onRemove={() => setShowAlert(false)}
          >
            <div>
              These integrations are in{' '}
              <a
                className={styles.link}
                target="_blank"
                rel="noreferrer"
                href="https://grafana.com/docs/release-life-cycle/"
              >
                public preview
              </a>
              . Thank you for trying them out!
            </div>
          </Alert>
        ) : null}

        {display === 'default' ? (
          <>
            <div className={styles.infoText}>When you install dashboards after setting up an integration below, both the GPU and LLM dashboards will be installed. 
              <br />For these dashboards to populate with data, you will need to go through the setup instructions below for each one separately.</div>
            <div className={styles.promptText}>Select the integration you would like to monitor:</div>
            <div className={styles.cardContainer}>
              <Card className={styles.card} onClick={() => setDisplay('gpu')}>
                <Card.Heading>Monitor GPU usage</Card.Heading>
                <p>NVIDIA Support</p>
                <Button>GPU Setup Instructions</Button>
              </Card>
              <Card className={styles.card} onClick={() => setDisplay('llm')}>
                <Card.Heading>Monitor LLMs</Card.Heading>
                <p>Monitor your LLM and Vector Database usage</p>
                <Button>LLM Setup Instructions</Button>
              </Card>
            </div>
          </>) : null}
        {display === 'gpu' ? (<GPUInstructions onBack={() => setDisplay('default')} />) : null}
        {display === 'llm' ? (<LLMInstructions onBack={() => setDisplay('default')} />) : null}
      </div>
    </div>
  );
};
