import { css } from '@emotion/css';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Spinner, useStyles2 } from '@grafana/ui';

import { StatusMessage } from 'components/StatusMessage';
import { Pages } from 'e2eSelectors/pages';
import { RootState } from 'state';
import { OnboardingPluginError } from 'types';
import { ConnectionStatus } from 'utils/enums';

const getStyles = (theme: GrafanaTheme2) => ({
  spinner: css`
    margin-right: ${theme.spacing(1)};
  `,
  connectionStatus: css`
    margin-top: 16px;
    margin-bottom: 0;
    font-size: ${theme.typography.bodySmall.fontSize};
  `,
});

const ConnectionStatusComponent = ({
  status,
  error,
  successMessage,
}: {
  status: ConnectionStatus;
  error?: OnboardingPluginError;
  successMessage?: string;
}) => {
  const styles = useStyles2(getStyles);

  switch (status) {
    case ConnectionStatus.Error:
      return (
        <StatusMessage
          className={styles.connectionStatus}
          status="error"
          ariaLabel="Connection error"
          dataTestId={Pages.SaaSIntegration.alertParagraph('connection-error')}
        >
          {error?.message}
          {error?.action}
        </StatusMessage>
      );
    case ConnectionStatus.Success:
      return (
        <StatusMessage
          className={styles.connectionStatus}
          status="success"
          ariaLabel="Connection success"
          dataTestId={Pages.SaaSIntegration.alertParagraph('connection-successful')}
        >
          {successMessage ? successMessage : 'All good! The account is working properly.'}
        </StatusMessage>
      );
    case ConnectionStatus.Pending:
      return (
        <p className={styles.connectionStatus} role="alert" aria-label="Checking connection">
          Checking connection. It might take up to a minute...
        </p>
      );
    default:
      return null;
  }
};

type ConnectionCheckButtonProps = {
  onSuccess?: () => void;
  onError?: (error: OnboardingPluginError | undefined) => void;
  disabled?: boolean;
  onCheckConnection: () => () => void;
  successMessage?: string;
};

export const ConnectionCheckButton: FC<ConnectionCheckButtonProps> = ({
  onSuccess,
  onError,
  disabled,
  onCheckConnection,
  successMessage,
}) => {
  const { connectionStatus, error } = useSelector((state: RootState) => state.saasIntegrations);
  const styles = useStyles2(getStyles);

  useEffect(() => {
    switch (connectionStatus) {
      case ConnectionStatus.Success: {
        onSuccess?.();
        break;
      }
      case ConnectionStatus.Error: {
        onError?.(error);
        break;
      }
    }
  }, [connectionStatus, error, onError, onSuccess]);

  return (
    <>
      <Button
        data-testid={Pages.SaaSIntegration.testConnectionButton}
        onClick={onCheckConnection()}
        aria-label="Test connection"
        disabled={connectionStatus === ConnectionStatus.Pending || disabled}
        type="button"
      >
        {connectionStatus === ConnectionStatus.Pending && <Spinner className={styles.spinner} />}
        Test Connection
      </Button>
      <ConnectionStatusComponent status={connectionStatus} error={error} successMessage={successMessage} />
    </>
  );
};
