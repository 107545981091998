import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Modal, Alert, Icon, Button, useStyles2 } from '@grafana/ui';

import { useGetLibraryPanelsQuery } from 'api/hostedGrafana/hostedGrafanaApi';
import { StatusMessage as CustomAlert } from 'components/StatusMessage';
import { TextLink } from 'components/TextLink';
import { unsetLocalError } from 'features/app/state/slice';
import { useDispatch } from 'hooks/useDispatch';
import useRudderStack from 'hooks/useRudderstack';
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { RootState } from 'state';
import { fetchFolderUIDs, updateIntegration } from 'state/source/actions';
import { InstallationStatus } from 'state/source/slice';
import { Source } from 'types/Source';
import { RudderStackEvents } from 'utils/enums';
import { constructDashboardUrl } from 'utils/misc';

import { getUpdateStyles } from './Update.styles';

interface UpdateModalProps {
  selectedIntegration: Source;
  setIsRecentlyUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UpdateModal = ({ selectedIntegration, setIsRecentlyUpdated, setShowModal }: UpdateModalProps) => {
  const styles = useStyles2((theme) => getUpdateStyles(theme));
  const integrationUpdateStatus = useSelector((state: RootState) => state.source.integrationUpdateStatus);
  const folderUIDs = useSelector((state: RootState) => state.source.folderUIDs || []);
  const { localError } = useSelector((state: RootState) => state.app);
  const { dashboard_folder } = useSelector((state: RootState) => state.source.sourceDetails);
  const { trackRudderStackEvent } = useRudderStack();

  const integrationId = useSelectedIntegrationId();
  const dispatch = useDispatch();

  useEffect(() => {
    if (integrationId) {
      dispatch(fetchFolderUIDs(integrationId));
    }
  }, [integrationId, dispatch]);

  const { data: libraryPanelsData } = useGetLibraryPanelsQuery(
    { folderFilterUIDs: folderUIDs },
    {
      skip: folderUIDs.length === 0,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }
  );

  const hasLibraryPanels = !!libraryPanelsData?.result?.elements?.length;

  const isUpdating = integrationUpdateStatus === InstallationStatus.Pending;

  const isButtonDisabled = isUpdating || hasLibraryPanels;

  const integrationDashboardsUrl = useMemo(
    () => (integrationId && dashboard_folder ? constructDashboardUrl(dashboard_folder) : '/dashboards'),
    [integrationId, dashboard_folder]
  );

  function onClickUpdateIntegration(): void {
    if (localError) {
      dispatch(unsetLocalError());
    }

    const trackingData = {
      integration_slug: integrationId,
      integration_version_old: selectedIntegration.installation?.version,
      integration_version_new: selectedIntegration.version,
    };
    trackRudderStackEvent(RudderStackEvents.UpdateButton, trackingData);

    dispatch(updateIntegration({ integrationId }));

    setIsRecentlyUpdated(true);
  }

  return (
    <Modal
      className={styles.overrideModal}
      title="Update Integration"
      isOpen={true}
      onDismiss={() => setShowModal(false)}
    >
      {hasLibraryPanels && (
        <Alert title={``} className={styles.alert} severity="warning">
          Move or remove any existing{' '}
          <TextLink href={integrationDashboardsUrl} inline>
            library panels from the integration&apos;s folder
          </TextLink>{' '}
          before updating.
        </Alert>
      )}
      <div className={styles.modalContent}>
        <p>We are constantly pushing updates to our integrations to add new default content and improve performance.</p>
        <div className={styles.alertText}>
          <Icon className={styles.icon} name="info-circle" size="lg" />
          <span>There may be downstream effects of updating this integration:</span>
        </div>
        <ol className={styles.text}>
          <li className={styles.text}>
            Updating this integration will overwrite any custom changes that have been made directly to the default
            dashboards and alerts. If you wish to keep any custom changes made to the included dashboards and alerts,
            make a copy of these before updating. Otherwise, if you used the data from this integration to create your
            own, separate custom dashboards and alerts, there will be no effect.
          </li>
          <li className={styles.text}>
            Updating this integration will momentarily remove prometheus alerts and recording rules associated with this
            integration. This may reset firing alerts and/or present a small gap in recording rule data.
          </li>
        </ol>
        <div className={styles.buttons}>
          <Button
            variant="primary"
            disabled={isButtonDisabled}
            onClick={onClickUpdateIntegration}
            title={hasLibraryPanels ? 'Please remove the library panels first' : ''}
          >
            {isUpdating && <Icon className={styles.icon} name="fa fa-spinner" />}
            Update Integration
          </Button>
          <Button
            variant="secondary"
            disabled={isUpdating}
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </Button>
        </div>
        {localError !== undefined && (
          <CustomAlert className={styles.errorAlert} status="error">
            {localError.message}
            {localError.action}
          </CustomAlert>
        )}
      </div>
    </Modal>
  );
};
