const seconds = Math.floor(Date.now() * 1000 * 1000);




export const otelBody = `
data='{
  "resourceMetrics": [
    {
      "scopeMetrics": [
        {
          "metrics": [
            {
              "name": "test_metric",
              "unit": "s",
              "description": "",
              "gauge": {
                "dataPoints": [
                  {
                    "asInt": 1,
                    "timeUnixNano": ${seconds},
                    "attributes": [
                      {
                        "key": "bar_label",
                        "value": {
                          "stringValue": "abc"
                        }
                      }
                    ]
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  ]
}'
`

export const graphiteBody = `
[
    {
        "name": "test.metric",
        "interval": 10,
        "value": 12.345,
        "tags": ["foo=baz", "source=grafana_cloud_docs"],
        "time": ${seconds}
    }
]`;

// PYTHON EXAMPLES
export const pythonCodeGraphite = (userId: number, token: string | undefined, url: string) => {
  return `
import requests
import json

USER_ID = ${userId}
API_KEY = "${!token ? 'your-api-key' : token}"

body = [
    {
        "name": "test.metric",
        "interval": 10,
        "value": 12.345,
        "tags": ["foo=bar", "source=grafana_cloud_docs"],
        "time": ${seconds}
    },
    {
        "name": "test.metric",
        "interval": 10,
        "value": 12.345,
        "tags": ["foo=baz", "source=grafana_cloud_docs"],
        "time": ${seconds}
    }
]

response = requests.post('${url}', 
                         headers = {
                           'Content-Type': 'application/json',
                           'Authorization': f'Bearer {USER_ID}:{API_KEY}'
                         },
                         data = str(json.dumps(body))
)

data = response.json()

print(data)
`;
};

export const pythonCodeOtel = (INSTANCE_ID: number, HOST: string, API_KEY: string | undefined) => {
  return`
import requests
import base64
HOST = \"${HOST}\"
API_KEY = \"${!API_KEY ? 'your-api-key' : INSTANCE_ID + ":" + API_KEY}\"
BODY = \"${otelBody}\"  

# Construct the URL
URL = f"https://{HOST}/otlp/v1/metrics"

# Send the HTTP request
response = requests.post(
    URL,
    headers={
        'Content-Type': 'text/plain',
        'Authorization': f"Bearer {API_KEY}"
    },
    data=BODY
)
`;
}


// RUBY EXAMPLES
export const rubyCodeGraphite = (userId: number, token: string | undefined, url: string) => {
  return `
require 'faraday'

USER_ID = ${userId}
API_KEY = "${!token ? 'your-api-key' : token}"

body = [
    {
        "name": "test.metric",
        "interval": 10,
        "value": 12.345,
        "tags": ["foo=bar", "source=grafana_cloud_docs"],
        "time": ${seconds}
    },
    {
        "name": "test.metric",
        "interval": 10,
        "value": 12.345,
        "tags": ["foo=baz", "source=grafana_cloud_docs"],
        "time": ${seconds}
    }
]

response = Faraday.post('${url}',
    body.to_json, 
    content_type: 'application/json',
    authorization: "Bearer #{USER_ID}:#{API_KEY}"
)

data = JSON.parse(response.body)

puts data
`;
};

export const rubyCodeOtel = (INSTANCE_ID: number, HOST: string, API_KEY: string | undefined) => {
  return `
require 'net/http'
require 'uri'
require 'base64'

HOST = \"${HOST}\"
API_KEY = \"${!API_KEY ? 'your-api-key' : INSTANCE_ID + ":" + API_KEY}\"


BASE64_ENCODED_AUTH = Base64.strict_encode64(API_KEY)

uri = URI.parse(${HOST})

BODY = "${otelBody}"

response = Net::HTTP.start(uri.host, uri.port, use_ssl: true) do |client|
  request                  = Net::HTTP::Post.new(uri.path)
  request.body             = BODY
  request["Authorization"] = "Basic #{BASE64_ENCODED_AUTH}"
  request["Content-Type"]  = "application/json"
  client.request(request)
end

data = response.code

puts data
`;
};


// NODE JS EXAMPLES
export const nodejsCodeOtel = (INSTANCE_ID: number, HOST: string, API_KEY: string | undefined) => {
  return `
import fetch from 'node-fetch';

const HOST = \"${HOST}\";
const API_KEY = \"${!API_KEY ? 'your-api-key' : INSTANCE_ID + ":" + API_KEY}\"
const BODY = "${otelBody}"

const URL = \`https://${HOST}/otlp/v1/metrics\`;

const response = await fetch(URL, {
    method: 'POST',
    body: BODY,
    headers: {
        'Authorization': \`Bearer ${API_KEY}\`,
        'Content-Type': 'application/json',
    },
});

const status = response.status;

console.log("Response Status:", status);
`;
};

export const nodejsCodeGraphite = (userId: number, token: string | undefined, url: string) => {
  return `
import fetch from 'node-fetch';

const USER_ID = ${userId}
const API_KEY = "${!token ? 'your-api-key' : token}"

const body = [
    {
        "name": "test.metric",
        "interval": 10,
        "value": 12.345,
        "tags": ["foo=bar", "source=grafana_cloud_docs"],
        "time": ${seconds}
    },
    {
        "name": "test.metric",
        "interval": 10,
        "value": 12.345,
        "tags": ["foo=baz", "source=grafana_cloud_docs"],
        "time": ${seconds}
    }
]

const response = await fetch('${url}', {
    method: 'post',
    body: JSON.stringify(body),
    headers: {
        'Authorization': \`Bearer $\{USER_ID}\:$\{API_KEY}\`, 
        'Content-Type': 'application/json',
    },
});


const data = await response.json()

console.log(data);
`;
};
// CURL EXAMPLES

export const curlCodeOtel = (INSTANCE_ID: number, HOST: string, API_KEY: string | undefined) => {
  return `
curl -k -i -XPOST -H 'Content-Type: application/json' -H "$OTEL_EXPORTER_OTLP_HEADERS" -u "${INSTANCE_ID}:${API_KEY}" ${HOST} -d "${otelBody}"`
};


export const curlCodeGraphite = (userId: number, token: string | undefined, url: string) => {
  return `
  API_KEY="${userId}:${!token ? 'your-api-key' : token}"
  URL="${url}"

curl -X  POST -H  "Authorization: Bearer $API_KEY" -H  "Content-Type: application/json" "$URL" -d '[{
        "name": "test.metric",
        "interval": 10,
        "value": 12.345,
        "tags": ["foo=bar", "source=grafana_cloud_docs"],
        "time": ${seconds}
    }]'
`;
};

// GO EXAMPLES
export const goCodeGraphite = (userId: number, token: string | undefined, url: string) => {
  return `
package main
import (
	"fmt"
	"bytes"
	"io/ioutil"
	"net/http"
 )
 

 func main() {
	byteValue := \`
 [
    {
        "name": "test.metric",
        "interval": 10,
        "value": 12.345,
        "tags": ["foo=bar", "source=grafana_cloud_docs"],
        "time": ${seconds}
    },
    {
        "name": "test.metric",
        "interval": 10,
        "value": 12.345,
        "tags": ["foo=baz", "source=grafana_cloud_docs"],
        "time": ${seconds}
    }
]
 \`

   API_KEY := "${userId}:${!token ? 'your-api-key' : token}"
   URL :="${url}"
	 var bearer = "Bearer " + API_KEY
	 var jsonStr = []byte(byteValue)
	 req, err := http.NewRequest("POST", URL, bytes.NewBuffer(jsonStr))
	 req.Header.Set("Content-Type", "application/json")
	 req.Header.Set("Authorization", bearer)
	 client := &http.Client{}
	 resp, err := client.Do(req)

	 if err != nil {
		 panic(err)
	 }

	 defer resp.Body.Close()
 
	 body, _ := ioutil.ReadAll(resp.Body)
	 fmt.Println("response Body:", string(body))

 }
`;
};

export const goCodeOtel = (INSTANCE_ID: number, HOST: string, API_KEY: string | undefined) => {
  return `
package main

import (
	"bytes"
	"fmt"
	"net/http"
	"os"
)

func main() {
	API_KEY := API_KEY = \"${!API_KEY ? 'your-api-key' : INSTANCE_ID + ":" + API_KEY}\"
	HOST := \"${HOST}\"
	BODY := \"${otelBody}}\" 

	URL := fmt.Sprintf(\"https://%s/otlp/v1/metrics\", HOST)

	req, err := http.NewRequest(\"POST\", URL, bytes.NewBuffer([]byte(BODY)))
	if err != nil {
		panic(err)
	}

	req.Header.Set(\"Content-Type\", \"application/json\")
	req.Header.Set(\"Authorization\", \"Bearer \"+API_KEY)

	client := &http.Client{}
	resp, err := client.Do(req)
	if err != nil {
		panic(err)
	}
	defer resp.Body.Close()

	fmt.Println(\"Response Status:\", resp.Status)
}`;
}



export const javaCodeGraphite = (userId: number, token: string | undefined, url: string) => {
  return `
import java.net.HttpURLConnection;
import java.net.URL;
import java.io.OutputStream;
import java.io.BufferedReader;
import java.io.InputStreamReader;

public class Main {
  public static void main(String[] args) {
     try {
         int userId = ${userId}; // your userId
         String apiKey = "${!token ? 'your-api-key' : token}";
         URL url = new URL("${url}");
         HttpURLConnection con = (HttpURLConnection) url.openConnection();
         con.setRequestMethod("POST");
         con.setRequestProperty("Content-Type", "application/json");
         con.setRequestProperty("Authorization", "Bearer " + userId + ":" + apiKey);

         String jsonInputString =
                 "[" +
                 "    {" +
                 "        \"name\": \"test.metric\"," +
                 "        \"interval\": 10," +
                 "        \"value\": 12.345," +
                 "        \"tags\": [\"foo=baz\", \"source=grafana_cloud_docs\"]," +
                 "        \"time\": ${seconds}" +
                 "    }" +
                 "]";

         con.setDoOutput(true);
         OutputStream os = con.getOutputStream();
         os.write(jsonInputString.getBytes());
         os.flush();
         os.close();

         BufferedReader in = new BufferedReader(
                 new InputStreamReader(con.getInputStream()));
         String inputLine;
         StringBuffer response = new StringBuffer();
         while ((inputLine = in.readLine()) != null) {
             response.append(inputLine);
         }
         in.close();

         System.out.println(response);
     } catch (Exception e) {
         System.out.println("Exception: " + e);
     }
     }
  
`;
};

export const javaCodeOtel = (INSTANCE_ID: number, HOST: string, API_KEY: string | undefined) => {
  return `
import java.net.HttpURLConnection;
import java.net.URL;
import java.io.OutputStream;
import java.io.BufferedReader;
import java.io.InputStreamReader;

public class Main {
  public static void main(String[] args) {
      try {
          String host = \"${HOST}\"; // your host
          String apiKey = \"${!API_KEY ? 'your-api-key' : INSTANCE_ID + ":" + API_KEY}\"
          URL url = new URL(\"https://${HOST}/otlp/v1/metrics\");
          HttpURLConnection con = (HttpURLConnection) url.openConnection();
          con.setRequestMethod("POST");
          con.setRequestProperty("Content-Type", "application/json");
          con.setRequestProperty("Authorization", "Bearer " + apiKey);
          String plainText = "${otelBody};"
          con.setDoOutput(true);
          OutputStream os = con.getOutputStream();
          os.write(plainText.getBytes());
          os.flush();
          os.close();

          int responseCode = con.getResponseCode();
          BufferedReader in = new BufferedReader(new InputStreamReader(con.getInputStream()));
          in.close();
          System.out.println("Response Code: " + responseCode);
      } catch (Exception e) {
          System.out.println("Exception: " + e);
      }
  }
}`;
};

