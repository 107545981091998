import React, { useState } from 'react';

import { RadioButtonGroup, useStyles2 } from '@grafana/ui';

import { useGetInstanceConnectionsQuery, useHostedDataDetailsWithFallback } from 'api/grafanaCom/grafanaComApi';
import { GenerateAPI } from 'components/GenerateAPI';
import { useApiToken } from 'hooks/useApiToken';
import { GRAFANA_EXAMPLE_API, DEFAULT_GRAPHITE_URL } from 'utils/consts';

import { getStyles } from './styles';
import { snippetTypeOptions, dataTypeOptions } from './utilities/dataTypes';
import  {
  curlCodeGraphite,
  goCodeGraphite,
  javaCodeGraphite,
  nodejsCodeGraphite,
  pythonCodeGraphite,
  rubyCodeGraphite,
  curlCodeOtel,
  pythonCodeOtel,
  goCodeOtel,
  nodejsCodeOtel,
  rubyCodeOtel,
  javaCodeOtel,
} from './utilities/snippets/codeSnippets';

export const HttpMetrics = () => {
  const styles = useStyles2(getStyles);
  const [dataType, setDataType] = useState<string>('otel');
  const [snippetGraf, setSnippetTypeGraf] = useState<string>('curl');
  const [snippetOtel, setSnippetTypeOtel] = useState<string>('curl');
  const { data: instanceConnectionsDetails } = useGetInstanceConnectionsQuery();
  const hostedDataDetails = useHostedDataDetailsWithFallback();
  const { token } = useApiToken();
  const otlpUrl = `${instanceConnectionsDetails?.otlpHttpUrl}/otlp/v1/metrics`;
  const instanceIdOtel = hostedDataDetails.id;
  const graphiteUrl = hostedDataDetails.hmInstanceGraphiteUrl ? `${hostedDataDetails.hmInstanceGraphiteUrl}/graphite/metrics` : DEFAULT_GRAPHITE_URL;
  const isMetricsSelected = dataType === 'otel';

  const displaySnippet = () => {
    return isMetricsSelected ? getSnippetTypeOtel(snippetOtel) : getSnippetTypeGraf(snippetGraf);
  };

  const getSnippetTypeGraf = (snippet: string) => {
    if (snippet === 'curl') {
      return curlCodeGraphite(hostedDataDetails.hmInstanceGraphiteId, token, graphiteUrl);
    } else if (snippet === 'python') {
      return pythonCodeGraphite(hostedDataDetails.hmInstanceGraphiteId, token, graphiteUrl);
    } else if (snippet === 'node') {
      return nodejsCodeGraphite(hostedDataDetails.hmInstanceGraphiteId, token, graphiteUrl);
    } else if (snippet === 'ruby') {
      return rubyCodeGraphite(hostedDataDetails.hmInstanceGraphiteId, token, graphiteUrl);
    } else if (snippet === 'go') {
      return goCodeGraphite(hostedDataDetails.hmInstanceGraphiteId, token, graphiteUrl);
    } else if (snippet === 'java') {
      return javaCodeGraphite(hostedDataDetails.hmInstanceGraphiteId, token, graphiteUrl);
    } else {
      return;
    }
  };

  const getSnippetTypeOtel = (snippet: string) => {
    if (snippet === 'curl') {
      return curlCodeOtel(instanceIdOtel, otlpUrl, token );
    } else if (snippet === 'python') {
      return pythonCodeOtel(instanceIdOtel,otlpUrl, token );
    } else if (snippet === 'node') {
      return nodejsCodeOtel(instanceIdOtel,otlpUrl, token );
    } else if (snippet === 'ruby') {
      return rubyCodeOtel(instanceIdOtel,otlpUrl, token );
    } else if (snippet === 'go') {
      return goCodeOtel(instanceIdOtel,otlpUrl, token);
    } else if (snippet === 'java') {
      return javaCodeOtel(instanceIdOtel,otlpUrl, token,);
    } else {
      return;
    }
  };

  const successMessage = 'Your API key has been generated below!';

  return (
    <>
      <h1>Configure sending metrics via HTTP</h1>
      <ol className={styles.instructions}>
        <li>
          <h2>Metrics Format: </h2>
          <p>
            <b>Select your metrics format (prometheus or graphite) to be used in your application.</b>
          </p>
          <RadioButtonGroup
            options={dataTypeOptions}
            value={dataType}
            onChange={(val) => setDataType(val)}
            className={styles.radioButtonGroup}
          />
        </li>
        <li>
          <h2>Generate API Key</h2>
          <p>
            <b>
              Generate an API Key to be used to authenticate your metrics (api key will be added to below code snippets
              once generated.)
            </b>
          </p>
          <GenerateAPI
            shouldShowClipboard={true}
            shouldShowInstruction={false}
            config={GRAFANA_EXAMPLE_API}
            instanceId={isMetricsSelected ? instanceIdOtel : hostedDataDetails.hmInstanceGraphiteId}
            successMessage={successMessage}
          />
          <li>
            <h2>Send a {isMetricsSelected ? 'Otel' : 'Graphite'} metric from your application code.</h2>
            <p>
              <b>Add the code snippet of your choice to your application and start pushing metrics to Grafana Cloud!</b>
            </p>
          </li>
          {isMetricsSelected ? (
            <RadioButtonGroup
              options={snippetTypeOptions}
              value={snippetOtel}
              onChange={(v) => setSnippetTypeOtel(v)}
              className={styles.radioButtonGroup}
            />
          ) : (
            <RadioButtonGroup
              options={snippetTypeOptions}
              value={snippetGraf}
              onChange={(v) => setSnippetTypeGraf(v)}
              className={styles.radioButtonGroup}
            />
          )}
          <pre>{displaySnippet()}</pre>
        </li>
      </ol>
    </>
  );
};
