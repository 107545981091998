import React from 'react';
import { useSelector } from 'react-redux';

import { Field, Switch, useStyles2 } from '@grafana/ui';

import { useSelectedSource } from 'api/integrations/hooks';
import { StatusMessage } from 'components/StatusMessage';
import { useDispatch } from 'hooks/useDispatch';
import useRudderStack from "hooks/useRudderstack";
import { useSelectedIntegrationId } from 'hooks/useSelectedIntegrationId';
import { RootState } from 'state';
import { RudderStackEvents } from "utils/enums";

import { isAlertsToggleDirty } from '../state/isAlertsToggleDirty';
import { toggleAlerts } from '../state/slice';

import { getStyles } from './AlertsToggle.styles';
import { FormPrompt } from './FormPrompt';


export const AlertsToggle = () => {
  const styles = useStyles2(getStyles);

  const selectedSource = useSelectedSource();
  const {
    configuredParameters: { isAlertsEnabled },
  } = useSelector((state: RootState) => state.agent);

  const dispatch = useDispatch();
  const integrationId = useSelectedIntegrationId();
  const { trackRudderStackEvent } = useRudderStack();

  const isDirty = useSelector((state: RootState) => isAlertsToggleDirty(state));
  const isOutdated = Boolean(selectedSource.has_update) && Boolean(selectedSource.installation);
  const howToSaveDescription = 'You can save your changes by clicking the "Save configuration selections" button.';

  return (
    <Field
      label="Include alerts"
      className={styles.toggleGroup}
      description="Decide whether you want to install alerts as part of this integration."
    >
      <>
        <FormPrompt
          // If the source is not installed, that means that this selection has not been saved yet.
          // In this case we should let the user leave the page without confirmation (i.e. not set this
          // dirty flag), because they didn't install the source - they might have just played around.
          confirmRedirect={isDirty && Boolean(selectedSource.installation)}
          onDiscard={() => {}}
          howToSaveDescription={howToSaveDescription}
        />
        {isOutdated && (
          <StatusMessage status="warning" className={styles.alert} role="status">
            Your installed version of this integration is outdated. Please update the integration first in order to
            change this configuration.
          </StatusMessage>
        )}
        <Switch
          role="switch"
          value={Boolean(isAlertsEnabled)}
          onChange={() => {
            if (integrationId != null) {
              dispatch(toggleAlerts(integrationId))
            }
            trackRudderStackEvent(RudderStackEvents.AlertsToggled, { integration_slug: integrationId });
          }}
          aria-label="Include alerts"
          disabled={isAlertsEnabled === undefined || isOutdated}
          aria-disabled={isAlertsEnabled === undefined || isOutdated}
        />
      </>
    </Field>
  );
};
