import { css } from '@emotion/css';
import _, {debounce} from "lodash";
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GroupProps } from 'react-select';

import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { Icon, Menu, Select, useStyles2 } from '@grafana/ui';

import { Components } from 'e2eSelectors/components';
import { useQueryParam } from 'hooks/useQueryParam';
import useRudderStack from 'hooks/useRudderstack';
import { EventSource, RudderStackEvents } from 'utils/enums';

import { useOptions, useRecents, useSuggestions } from './hooks';
import { SemanticSearchOption, SemanticSearchOptionType } from './utils';

const getStyles = (theme: GrafanaTheme2) => ({
  searchContainer: css`
    display: flex;
    margin: 0 0 16px 0;
    justify-content: space-between;
  `,
  horizontalDivider: css({
    borderTop: `1px solid ${theme.colors.border.weak}`,
    margin: theme.spacing(0.5, 0),
    width: '100%',
  }),
});

const MenuGroupWithDivider = (props: GroupProps<{ label: string; value: string }>) => {
  const styles = useStyles2(getStyles);
  return (
    <>
      {/* For some unknown reason Menu.Divider gets hidden when the menu is so large
          that a scrollbar is shown.
          The Divider component was introduced to grafana-ui in 10.1.0, so this is a copy of its implementation.
          We need it to support older Grafana versions than 10.1.0.
      */}
      <hr className={styles.horizontalDivider} />
      <Menu.Group label={props.data.label}>{props.children}</Menu.Group>
    </>
  );
};

export const SemanticSearch: FC = () => {
  const styles = useStyles2(getStyles);
  const history = useHistory();

  const { options: allOptions, isLoading } = useOptions();
  const { recents, updateStoredSelections, removeAgentInstallationEntry } = useRecents();
  const suggestedOptions = useSuggestions(allOptions);
  const { trackRudderStackEvent } = useRudderStack();

  const [searchQueryParam, setSearchQueryParam] = useQueryParam('search', (value, fromURL) => {
    const trimmedValue = value?.trim() ?? '';
    const source = fromURL ? EventSource.URL : EventSource.Type;

    const debouncedTrackEvent = debounce((searchTerm) => {
      trackRudderStackEvent(RudderStackEvents.SearchInput, { searchTerm  });
    }, 500);

    if (source === EventSource.URL && trimmedValue) {
      trackRudderStackEvent(RudderStackEvents.SearchSetFromUrl, { searchTerm: trimmedValue });
    } else {
      debouncedTrackEvent(trimmedValue);
    }
  });

  const [searchCatQueryParam, setSearchCatQueryParam] = useQueryParam('searchCat', (value, fromURL) => {
    const trimmedValue = value?.trim() ?? '';
    const source = fromURL ? EventSource.URL : EventSource.Type;

    if (source === EventSource.URL && trimmedValue) {
      trackRudderStackEvent(RudderStackEvents.SearchCategorySetFromUrl, { searchTerm: trimmedValue });
    } else{
      trackRudderStackEvent(RudderStackEvents.SearchCategorySelected, { searchTerm: trimmedValue });
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => removeAgentInstallationEntry(), []);

  const isRecentsEmpty = recents[0].options.length === 0;
  const optionsForEmptyInput = useMemo(
    () => (!isRecentsEmpty ? recents : suggestedOptions),
    [isRecentsEmpty, recents, suggestedOptions]
  );

  const [showAllOptions, setShowAllOptions] = useState<boolean>(false);
  const options = showAllOptions ? allOptions : optionsForEmptyInput;

  const onInputChange = useCallback(
    (value: string) => {
      if (value === '') {
        setShowAllOptions(false);
      } else {
        setShowAllOptions(true);
      }
    },
    [setShowAllOptions]
  );

  const onChange = (value: SelectableValue<string>) => {
    if (value === null) {
      setSearchCatQueryParam(undefined);
      setSearchQueryParam(undefined);
      return;
    }
    const selectedOption = value as SemanticSearchOption;

    updateStoredSelections(selectedOption);
    setShowAllOptions(false);

    switch (selectedOption.type) {
      case SemanticSearchOptionType.Category:
        setSearchQueryParam(undefined);
        setSearchCatQueryParam(selectedOption.value);
        break;
      case SemanticSearchOptionType.SourceWithLink:
        trackRudderStackEvent(RudderStackEvents.SearchExactMatchSelected, { selectedEntry: selectedOption.label });
        history.push(selectedOption.url);
        break;
      case SemanticSearchOptionType.SourceWithModal:
        // Click on the card triggers a modal, so let's show this one card only
        setSearchQueryParam(selectedOption.label);
        setSearchCatQueryParam(undefined);
        break;
      default: // it is a custom value
        // TS believes that all cases are handled except for the default,
        // so it infers the default case as never throwing an error
        // using type assertion to ensure TS understands this as a valid SemanticSearchOption
        const defaultOption = selectedOption as SelectableValue<string> & { type?: SemanticSearchOptionType };
        setSearchQueryParam(defaultOption.value);
        setSearchCatQueryParam(undefined);
        break;
    }
  };

  // Passing the searchCatQueryParam string to value is not enough when the options do not contain the value.
  // This can happen, when options is recents. We need to pass an option object to fix this.
  const categoryFromUrl = allOptions[0].options.find((v: SemanticSearchOption) => v.value === searchCatQueryParam);

  function formatCreateLabel(input: string) {
    return (
      <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <div>{input}</div>
        <div style={{ flexGrow: 1 }} />
        <div className="muted small" style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          Hit enter to apply
        </div>
      </div>
    );
  }

  return (
    <div className={styles.searchContainer}>
      <Select
        onChange={onChange}
        allowCustomValue
        isClearable
        createOptionPosition="first"
        options={options}
        prefix={<Icon name="search" />}
        placeholder="Search connections"
        aria-label="Search connections by name"
        data-testid={Components.Search.searchInput}
        value={
          searchCatQueryParam
            ? categoryFromUrl
            : searchQueryParam
              ? { label: searchQueryParam, value: searchQueryParam }
              : null
        }
        components={{ Group: MenuGroupWithDivider }}
        isLoading={isLoading}
        onInputChange={onInputChange}
        formatCreateLabel={formatCreateLabel}
      />
    </div>
  );
};
