import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';

function encodeToBase64(input: string): string {
  return btoa(input);
}

export const openLitConfiguration = (
  endpoint: string | undefined,
  instanceId: string | undefined,
  token: string | undefined
): string => {
  return `export OTEL_EXPORTER_OTLP_ENDPOINT = "${!endpoint ? 'https://otlp-gateway-[url].grafana.net/otlp' : endpoint}"
export OTEL_EXPORTER_OTLP_HEADERS = "Authorization=Basic%20${!token ? '<base64 encoded grafana cloud api key>' : encodeToBase64(instanceId + ":" + token)}"`;
};

export const otelCollectorConfiguration = (
  endpoint: string | undefined,
  instanceId: string | undefined,
  token: string | undefined
): string => {
return `docker run --gpus all 
-e GPU_APPLICATION_NAME='APPLICATION_NAME' \
-e GPU_ENVIRONMENT='ENVIRONMENT' \
-e OTEL_EXPORTER_OTLP_ENDPOINT="${!endpoint ? 'https://otlp-gateway-[url].grafana.net/otlp' : endpoint}" \
-e OTEL_EXPORTER_OTLP_HEADERS="Authorization=Basic%20${!token ? '<base64 encoded grafana cloud api key>' : encodeToBase64(instanceId + ":" + token)}" \
ghcr.io/openlit/otel-gpu-collector:latest`
};


export const getInstructionStyles = (theme: GrafanaTheme2) => ({
  container: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  pageHeader: css({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    '& h1': {
      margin: '0px',
      display: 'flex',
      alignItems: 'center',
    },
  }),
  instructionList: css({
    '> li': {
      listStylePosition: 'inside',
      marginBottom: '70px',
      marginLeft: '0px !important',
      h2: {
        display: 'inline',
      },
      '::marker': {
        fontWeight: theme.typography.h2.fontWeight,
        fontSize: theme.typography.h2.fontSize,
        lineHeight: '26px',
      },
    },
  }),
  instructions: css({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    background: theme.colors.background.secondary,
    padding: theme.spacing(2),
    '> div div': {
      '> pre': {
        width: '95%',
      }
    },
  }),
  installCard: css({
    '> h3': {
      display: 'none'
    }
  })
});
