import { css } from '@emotion/css';
import React, { useState } from 'react';

import { config } from '@grafana/runtime';
import { Card, LinkButton, Modal, useStyles2 } from '@grafana/ui';

import { Markdown } from 'components/Markdown';
import { ModalTitle } from 'components/ModalTitle';
import { useDispatch } from 'hooks/useDispatch';
import useRudderStack from "hooks/useRudderstack";
import { Source } from 'types/Source';
import { RudderStackEvents } from "utils/enums";
import { constructDashboardUrl, INTEGRATIONS_URL } from 'utils/misc';
import { isAdmin } from 'utils/user';

import { clickIntegrationCard, clickIntegrationCardAlerts, clickIntegrationCardDashboards } from './state/slice';


const getStyles = () => ({
  logo: css`
    object-fit: contain;
    height: 32px;
  `,
  modal: css`
    width: 531px;
  `,
  modalContent: css`
    a {
      text-decoration: underline;
    }
  `,
  button: css`
    margin-left: 14px;
  `,
});

export const IntegrationItem = ({ integration }: { integration: Source }) => {
  const styles = useStyles2(getStyles);
  const dispatch = useDispatch();
  const { trackRudderStackEvent } = useRudderStack();

  const [showModal, setShowModal] = useState(false);

  function onCardClick() {
    if (!isAdmin()) {
      setShowModal(true);
    }

    dispatch(clickIntegrationCard(integration.id));
    trackRudderStackEvent(RudderStackEvents.InfraCardClick, { integration_slug: integration.id });
  }

  const href = isAdmin() ? `${INTEGRATIONS_URL}/${integration.id}` : '';
  const logo_url =
    config.theme2.isLight && integration.light_logo_url ? integration.light_logo_url : integration.logo_url;

  return (
    <Card key={integration.id} href={href} onClick={() => onCardClick()}>
      <Card.Heading>{integration.name}</Card.Heading>
      <Card.Figure>
        <img src={logo_url} className={styles.logo} alt="Logo" />
      </Card.Figure>
      <Card.Tags>
        {integration.dashboard_folder && (
          <LinkButton
            icon="apps"
            fill="outline"
            variant="secondary"
            href={constructDashboardUrl(integration.dashboard_folder)}
            onClick={() => {
              dispatch(clickIntegrationCardDashboards(integration.id))
              trackRudderStackEvent(RudderStackEvents.InfraCardDashboardsClick, { integration_slug: integration.id });

            }}
          >
            Dashboards
          </LinkButton>
        )}
        <LinkButton
          className={styles.button}
          icon="bell"
          fill="outline"
          variant="secondary"
          href={`/alerting/list${integration.rule_namespace ? `?search=namespace:${integration.rule_namespace}` : ''}`}
          onClick={() => {
            dispatch(clickIntegrationCardAlerts(integration.id))
            trackRudderStackEvent(RudderStackEvents.InfraCardAlertsClick, { integration_slug: integration.id });
          }}
        >
          Alerts
        </LinkButton>
      </Card.Tags>
      {showModal && (
        <Modal
          className={styles.modal}
          title={<ModalTitle item={integration} />}
          isOpen={showModal}
          onDismiss={() => setShowModal(false)}
        >
          <div className={styles.modalContent}>
            {integration.overview && <Markdown markdownSnippet={integration.overview} />}
          </div>
        </Modal>
      )}
    </Card>
  );
};
